<template>
  <div>
    <div class="flex align-items-center mb-2 justify-content-between">
      <h2 class="text-xl font-semibold">Bình luận mới nhất</h2>
      <next-prev
          v-on:clickPrev="comments.paginatorInfo.currentPage = comments.paginatorInfo.currentPage - 1; loadStickyComments()"
          v-on:clickNext="comments.paginatorInfo.currentPage = comments.paginatorInfo.currentPage + 1; loadStickyComments()"
      />
    </div>

    <ul class="divide-y" v-if="comments.data && comments.data.length">
      <latest-comment-item :length="50" :sticky="true" :comment="comment" v-for="(comment, index) in stickyComments"
                           :key="'comment' + index"/>
      <latest-comment-item :length="50" :comment="comment" v-for="(comment, index) in comments.data" :key="'comment' + index"/>
    </ul>

    <div class="mt-2">
      <v-pagination
          class="justify-content-end"
          v-if="comments.paginatorInfo.lastPage > 1"
          v-model="comments.paginatorInfo.currentPage"
          :pages="comments.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadComments"
      />
    </div>
  </div>
</template>

<script>
import NextPrev from "../NextPrev";
import LatestCommentItem from "./LatestCommentItem";
import ApiService from "../../core/services/api.service";
import {censorBadWords} from "../../core/services/utils.service";

export default {
  name: "LatestComment",
  components: {
    NextPrev, LatestCommentItem
  },
  data() {
    return {
      comments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 10
        }
      },
      stickyComments: []
    }
  },
  methods: {
    loadComments() {
      let except = [];
      if (this.stickyComments.length) {
        this.stickyComments.map((comment) => {
          except.push({
            OR: [
              {column: "commentable_id", value: comment.commentable_id, operator: "NEQ"},
              {column: "commentable_type", value: comment.commentable_type, operator: "NEQ"}
            ]
          });
        });
      }
      let where = {
        AND: [
          {AND: except}
        ]
      };

      let query = `query($page: Int, $first: Int, $where: WhereConditions) {
        latestComments(first: $first, where: $where, page: $page) {
          data {
            id
            content
            created_at
            user {
              id
              username
              avatar {
                url
              }
            }
            object {
              __typename
              ... on Song {
                id
                title
                slug
              }
              ... on Folk {
                id
                title
                slug
              }
              ... on Instrumental {
                id
                title
                slug
              }
              ... on Karaoke {
                id
                title
                slug
              }
              ... on Poem {
                id
                title
                slug
              }
              ... on Composer {
                id
                title
                slug
              }
              ... on Artist {
                id
                title
                slug
              }
              ... on Poet {
                id
                title
                slug
              }
              ... on Recomposer {
                id
                title
                slug
              }
              ... on Document {
                id
                title
                slug
              }
              ... on Discussion {
                id
                title
                slug
              }
              ... on Playlist {
                id
                title
                slug
              }
              ... on Sheet {
                id
                title
                slug
              }
              ... on Page {
                id
                title
                slug
              }
              ... on VovProgram {
                id
                title
                slug
              }
              ... on Role {
                id
                name
                slug
              }
              ... on Upload {
                id
              }
              ... on Tag {
                name
                slug
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {where: where, page: this.comments.paginatorInfo.currentPage, first: 8})
          .then(({data}) => {
            if (data.data && data.data.latestComments) {
              this.comments.data = data.data.latestComments.data;
              if (this.comments.data && this.comments.data.length) {
                this.comments.data.map((comment) => {
                  comment.content = censorBadWords(comment.content);
                });
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadStickyComments() {
      let query = `query {
        stickyComments {
          id
          content
          created_at
          user {
            id
            username
            avatar {
              url
            }
          }
          object {
            __typename
            ... on Song {
              id
              title
              slug
            }
            ... on Folk {
              id
              title
              slug
            }
            ... on Instrumental {
              id
              title
              slug
            }
            ... on Karaoke {
              id
              title
              slug
            }
            ... on Poem {
              id
              title
              slug
            }
            ... on Composer {
              id
              title
              slug
            }
            ... on Artist {
              id
              title
              slug
            }
            ... on Poet {
              id
              title
              slug
            }
            ... on Recomposer {
              id
              title
              slug
            }
            ... on Document {
              id
              title
              slug
            }
            ... on Discussion {
              id
              title
              slug
            }
            ... on Playlist {
              id
              title
              slug
            }
            ... on Sheet {
              id
              title
              slug
            }
            ... on Page {
              id
              title
              slug
            }
            ... on VovProgram {
              id
              title
              slug
            }
            ... on Role {
              id
              name
              slug
            }
          }
          commentable_type
          commentable_id
        }
      }
      `;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.stickyComments) {
              this.stickyComments = data.data.stickyComments;
              if (this.stickyComments.length) {
                this.stickyComments.map((comment) => {
                  comment.content = censorBadWords(comment.content);
                });
              }
              this.loadComments();
            } else {
              this.loadComments();
            }
          })
          .catch((response) => {
            console.log(response);
            this.loadComments();
          });
    }
  },
  mounted() {
    this.loadStickyComments();
  }
}
</script>
