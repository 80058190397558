<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-4/6 space-y-7">
      <div class="card p-4">
        <h1 class="text-2xl font-semibold">{{ upload.title ? upload.title : upload.link }}</h1>
        <div class="vov-program mt-3">
          <p v-if="upload.type === 'replace'">File muốn thay thế:</p>
          <Embed :type="upload.file.type"
                :unique="Math.round(Math.random() * 1000000) + 'upload' + upload.id"
                :key="Math.round(Math.random() * 1000000) + 'upload' + upload.id"
                :src="upload.file.type === 'audio' ? upload.file.audio_url : upload.file.video_url" 
                v-if="upload.file"/>
          <div v-if="upload.link" class="mt-2">
            <p>File gốc:</p>
            <Embed :showInfoProp="true" 
                :unique="Math.round(Math.random() * 1000000) + 'upload' + upload.id"
                :key="Math.round(Math.random() * 1000000) + 'upload' + upload.id"
                :src="upload.link"/>
          </div>
          <div v-if="upload.thumbnail">
            <img :src="upload.thumbnail.url"/>
          </div>
          <div v-html="upload.content"></div>
      </div>
        <div class="vov-program mt-4">
          <div class="mt-3 space-x-2">
            <span
              :class="'whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full ' + getUploadStatusClass(upload.status)">{{
              getUploadStatusText(upload.status)
            }}</span>
            <span
                :class="'whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full ' + getUploadTypeClass(upload.type)">{{
                getUploadTypeText(upload.type)
              }}</span>
            <span>
              <router-link v-if="upload.user" class="font-semibold whitespace-nowrap"
                          :to="{name: 'UserDetail', params: {id: upload.user.id}}">
                {{ upload.user.username }}
              </router-link>
              ;
            </span>
            <span v-if="upload.composers">Sáng tác: {{ upload.composers }};</span>
            <span v-if="upload.artists">Trình bày: {{ upload.artists }};</span>
            <span v-if="upload.poets">Thơ: {{ upload.poets }};</span>
            <span v-if="upload.recomposers">Soạn lời: {{ upload.recomposers }};</span>
            <span v-if="upload.fcats">Thể loại: {{ upload.fcats }};</span>
            <span v-if="upload.melodies">Làn điệu: {{ upload.melodies }};</span>
            <span>{{ timeago(upload.created_at) }}</span>
          </div>

          <div class="mt-4">
            <comment object-type="upload" :object-id="id" :object-user-id="upload.user_id" field="id" type="ID" :comment-private="upload.comment_private"/>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:w-2/6 w-full">
      <div class="card p-3">
        <latest-comment/>
      </div>
      <right/>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import Comment from "../comment/Comment";
import Embed from "../Embed";
import LatestComment from "../comment/LatestComment";

import {changePageTitle, logActivity, timeago, getUploadTypeText, getUploadTypeClass, getUploadStatusClass,
  getUploadStatusText} from "../../core/services/utils.service";

export default {
  name: "UploadDetail",
  components: {Comment, Embed, LatestComment},
  data() {
    return {
      id: null,
      upload: {
        title: ""
      }
    }
  },
  methods: {
    loadUpload() {
      let query = `query($id: ID!) {
        upload(id: $id) {
          id
          title
          content
          link
          note
          created_at
          status
          type
          composers
          artists
          poets
          recomposers
          fcats
          melodies
          result
          reason
          user_id
          comment_private
          user {
            id
            username
          }
          processor {
            id
            username
          }
          thumbnail {
            url
          }
          file {
            audio_url
            video_url
            type
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.upload) {
              this.upload = data.data.upload;
              changePageTitle('Bản upload: ' + this.upload.id);
              logActivity("view", "upload", this.id);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    timeago(x) {
      return timeago(x);
    },
    getUploadTypeText(x) {
      return getUploadTypeText(x);
    },
    getUploadTypeClass(x) {
      return getUploadTypeClass(x);
    },
    getUploadStatusText(x) {
      return getUploadStatusText(x);
    },
    getUploadStatusClass(x) {
      return getUploadStatusClass(x);
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadUpload();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    window.scrollTo(0, 0);
    next();
    this.loadUpload();
  },
}
</script>
