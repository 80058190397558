<template>
  <li class="py-2">
    <div class="flex items-start space-x-4 p-1">
      <router-link
        :to="{ name: 'UserDetail', params: { id: comment.user.id } }"
        v-if="comment.user"
        :title="comment.user.username"
      >
        <img
          :src="
            comment.user && comment.user.avatar
              ? comment.user.avatar.url
              : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'
          "
          :alt="comment.user.username"
          class="w-12 h-12 rounded-full object-cover max-width-inherit"
        />
      </router-link>
      <div class="flex-1">
        <h3 class="text-lg font-semibold mb-1">
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'SongDetail',
              hash: '#comment-' + comment.id,
              params: {
                id: comment.object.id,
                slug: comment.object.slug,
                prefix: getPrefixFromSongType(comment.object['__typename']),
              },
            }"
            v-if="comment.object && isSongable(comment.object['__typename'])"
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'PeopleDetail',
              hash: '#comment-' + comment.id,
              params: {
                slug: comment.object.slug,
                prefix: getRoutePrefixPeople(comment.object['__typename']),
              },
            }"
            v-if="comment.object && isPeople(comment.object['__typename'])"
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'DocumentDetail',
              hash: '#comment-' + comment.id,
              params: { id: comment.object.id, slug: comment.object.slug },
            }"
            v-if="comment.object && comment.object['__typename'] === 'Document'"
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'DiscussionDetail',
              hash: '#comment-' + comment.id,
              params: { id: comment.object.id, slug: comment.object.slug },
            }"
            v-if="
              comment.object && comment.object['__typename'] === 'Discussion'
            "
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'PlaylistDetail',
              hash: '#comment-' + comment.id,
              params: { id: comment.object.id, slug: comment.object.slug },
            }"
            v-if="comment.object && comment.object['__typename'] === 'Playlist'"
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'SheetDetail',
              hash: '#comment-' + comment.id,
              params: { id: comment.object.id, slug: comment.object.slug },
            }"
            v-if="comment.object && comment.object['__typename'] === 'Sheet'"
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'PageDetail',
              hash: '#comment-' + comment.id,
              params: { slug: comment.object.slug },
            }"
            v-if="comment.object && comment.object['__typename'] === 'Page'"
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'VovProgramDetail',
              hash: '#comment-' + comment.id,
              params: { id: comment.object.id, slug: comment.object.slug },
            }"
            v-if="
              comment.object && comment.object['__typename'] === 'VovProgram'
            "
            :title="comment.object.title"
          >
            {{ comment.object.title }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'RoleDetail',
              hash: '#comment-' + comment.id,
              params: { slug: comment.object.slug },
            }"
            v-if="comment.object && comment.object['__typename'] === 'Role'"
            :title="comment.object.name"
          >
            {{ comment.object.name }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{
              name: 'UploadDetail',
              hash: '#comment-' + comment.id,
              params: { id: comment.object.id },
            }"
            v-if="comment.object && comment.object['__typename'] === 'Upload'"
            :title="'Upload: ' + comment.object.id"
          >
            {{ "Upload: " + comment.object.id }}
          </router-link>
          <router-link
            class="line-clamp-2"
            :to="{ name: 'TagDetail', params: { name: comment.object.slug } }"
            v-if="comment.object && comment.object['__typename'] === 'Tag'"
            :title="'Tag: ' + comment.object.name"
          >
            {{ "Tag: " + comment.object.name }}
          </router-link>
          <span v-if="sticky" class="ml-2"
            ><i class="fas fa-thumbtack"></i
          ></span>
        </h3>
        <p class="text-sm text-gray-400 mb-2 space-x-2">
          <router-link
            class="text-dot no-dot text-sm"
            :to="{ name: 'UserDetail', params: { id: comment.user.id } }"
            v-if="comment.user"
            :title="comment.user.username"
          >
            {{ comment.user.username }}
          </router-link>
          <span class="text-dot text-sm">{{
            timeago(comment.created_at)
          }}</span>
        </p>
        <p class="">{{ stripContent(comment.content, length) }}</p>
      </div>
    </div>
  </li>
</template>

<script>
import {
  getPrefixFromSongType,
  getRoutePrefixPeople,
  isPeople,
  isSongable,
  stripContent,
  timeago,
} from "../../core/services/utils.service";

export default {
  name: "LatestCommentItem",
  props: {
    comment: Object,
    sticky: Boolean,
    length: Number,
  },
  methods: {
    stripContent(content, length) {
      return stripContent(content, length);
    },
    timeago(x) {
      return timeago(x);
    },
    getPrefixFromSongType(type) {
      type = type.toLowerCase();
      return getPrefixFromSongType(type);
    },
    isSongable(type) {
      return isSongable(type);
    },
    getRoutePrefixPeople(type) {
      type = type.toLowerCase();
      return getRoutePrefixPeople(type);
    },
    isPeople(type) {
      return isPeople(type);
    },
  },
};
</script>
